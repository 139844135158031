import request from "@/utils/request";

//获取公众号用户openid
export function postWechatH5Openid(params) {
  return request({
    url: "/userinfo/uia/queryWechatH5Openid",
    method: "GET",
    params,
  });
}

//进行微信工作待办通知绑定
export function postSyncUserOpenid(data) {
  return request({
    url: "/pclogin/policy/syncUserOpenid",
    method: "POST",
    data,
  });
}

//查询微信工作待办通知绑定
export function postQueryUserByOpenid(openid) {
  return request({
    url: "/pclogin/policy/queryUserByOpenid?openid=" + openid,
    method: "POST",
  });
}
