import service from "@/utils/request";

// 郑好办code登录
export function zhbLogin(data) {
  return service({
    url: `/talent-common/uia/zhbLogin/${data.authCode}`,
    method: "POST",
  });
}
// 微信code登录 获取openid或者token
export function wxLogin(params) {
  return service({
    url: `/userinfo/uia/wechatH5Login`,
    method: "get",
    params
  });
}

// openid实名认证
export function postRealNameAuthen(data) {
  return service({
    url: `/userinfo/uia/H5RealNameAuthen`,
    method: "post",
    data
  });
}

//获取验证码
export function getVerifyCode(params) {
  return service({
    url: "/userinfo/sms/sendSMSCodeFr",
    method: "get",
    params
  });
}
