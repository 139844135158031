import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        redirect: "/home",
      },
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/Home"),
        meta: {
          title: "首页",
        },
      },

      {
        path: "/newsList",
        name: "newsList",
        component: () => import("@/views/NewsList"),
        meta: {
          title: "新闻列表",
        },
      },
      {
        path: "/newsDetail/:id",
        name: "newsDetail",
        component: () => import("@/views/NewsDetail"),
        meta: {
          title: "新闻详情",
        },
      },
      {
        path: "/guide",
        name: "guide",
        component: () => import("@/views/talent/guide"),
        meta: {
          title: "办事项指南",
        },
      },
      {
        path: "/todoGuide",
        name: "todoGuide",
        component: () => import("@/views/talent/todoGuide"),
        meta: {
          title: "办事项指南",
        },
      },
      {
        path: "/guideDetail",
        name: "guideDetail",
        component: () => import("@/views/talent/guideDetail"),
        meta: {
          title: "办事项详情",
        },
      },
      {
        path: "/question",
        name: "question",
        component: () => import("@/views/talent/question"),
        meta: {
          title: "问卷调查",
        },
      },
      {
        path: "/application",
        name: "application",
        component: () => import("@/views/application"),
        meta: {
          title: "办事项申请",
        },
      },
      {
        path: "/apply/:id",
        name: "apply",
        component: () => import("@/views/apply"),
        meta: {
          title: "办事项申请",
        },
      },
      {
        path: "/applylist",
        name: "ApplyList",
        component: () => import("@/views/ApplyList"),
        meta: {
          title: "办事进度",
        },
      },
      {
        path: "/applyDetail/:id",
        name: "ApplyDetail",
        component: () => import("@/views/ApplyDetail"),
        meta: {
          title: "办事详情",
        },
      },
      {
        path: "/realauth",
        name: "realauth",
        component: () => import("@/views/RealAuth"),
        meta: {
          title: "实名认证",
        },
      },
      {
        path: "/hongbao",
        name: "hongbao",
        component: () => import("@/views/hongbao"),
        meta: {
          title: "2023过年红包",
        },
      },
      {
        path: "/job",
        name: "job",
        component: () => import("@/views/Job"),
        meta: {
          title: "人才招聘",
        },
      },
      {
        path: "/jobR",
        name: "jobR",
        component: () => import("@/views/Job"),
        meta: {
          title: "人才招聘-郑州航空港人力资源综合服务中心",
        },
      },
      // {
      //   path: "/jobfairs/:id",
      //   name: "Jobfairs",
      //   component: () => import("@/views/Jobfairs"),
      //   meta: {
      //     title: "人才招聘会",
      //   },
      // },
      {
        path: "/jobfair/:id",
        name: "Jobfair",
        component: () => import("@/views/Jobfair"),
        meta: {
          title: "人才招聘会",
        },
      },
      {
        path: "/jobfairsList",
        name: "jobfairsList",
        component: () => import("@/views/jobfairsList"),
        meta: {
          title: "人才招聘会",
        },
      },
      {
        path: "/jobfairHome",
        name: "jobfairHome",
        component: () => import("@/views/jobFairHome"),
        meta: {
          title: "招聘",
        },
      },

      {
        path: "/jobDetail",
        name: "jobDetail",
        component: () => import("@/views/JobDetail"),
        meta: {
          title: "招聘详情",
        },
      },
      {
        path: "/needTalent",
        name: "needTalent",
        component: () => import("@/views/needTalent"),
        meta: {
          title: "人才需求目录",
        },
      },
      {
        path: "/rental-house",
        name: "RentalHouse",
        component: () => import("@/views/HouseSurvey/RentalHouse"),
        meta: {
          title: "航空港区居民小区可出租房屋数量统计表",
        },
      },
      {
        path: "/talent-house",
        name: "RentalHouse",
        component: () => import("@/views/HouseSurvey/TalentHouse"),
        meta: {
          title: "航空港区人才公寓需求统计表",
        },
      },
      {
        path: "/talent-house-personal",
        name: "TalentHousePersonal",
        component: () => import("@/views/HouseSurvey/TalentHousePersonal"),
        meta: {
          title: "航空港区人才公寓个人需求统计表",
        },
      },
      {
        path: "/talent-activities",
        name: "TalentActivities",
        component: () => import("@/views/HouseSurvey/TalentActivities"),
        meta: {
          title: "航空港区人才活动周报名",
        },
      },
    ],
  },
  {
    path: "/wxNotice",
    name: "wxNotice",
    component: () => import("@/views/wxNotice"),
    meta: {
      title: "微信账号绑定",
    },
  },
  {
    path: "*",
    name: "404",
    meta: {
      title: 404,
    },
    component: () => import("@/views/404"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
