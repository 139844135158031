import * as jobfairsApi from "@/api/jobfairs";
import { checkErrCode } from "@/utils";
const state = {};

const mutations = {};

const actions = {
  //获取招聘会详情
  getJobfairsInfo({ commit }, param) {
    const p = {
      apiName: "/recruit/jobfairs/list",
      paramMap: param,
      requestType: 1,
    };
    return new Promise((resolve, reject) => {
      jobfairsApi.getJobfairsInfo(p).then((res) => {
        if (checkErrCode(res)) {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  },
  //获取招聘会职位列表
  postJobfairsJobList({ commit }, param) {
    const p = {
      apiName: "/recruit/jobfairs-apply/queryJobH5OrXcx",
      paramMap: param,
      requestType: 1,
    };
    return new Promise((resolve, reject) => {
      jobfairsApi.postJobfairsJobList(p).then((res) => {
        if (checkErrCode(res)) {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
