import floor from "lodash/floor";

/**
 * @description:检查接口请求是否状态值是否是 200
 * @param {*} resp 接口对象
 * @return {*} code ===200 true
 */
export function checkErrCode(resp) {
  return resp ? (resp.code === 200 ? true : false) : false;
}

//获取金额字段
const payCycleMap = [
  { value: 1, label: "日" },
  { value: 2, label: "周" },
  { value: 3, label: "月" },
  { value: 4, label: "年" }
];

export function getSalaryText(jobItem) {
  if (!jobItem) return "";
  const { flag, payCycle, salaryDescribe, jobMinSalary, jobMaxSalary } =
    jobItem;
  let str = "";
  if (flag == 1) {
    str = "面议";
  } else if (flag == 2) {
    const c = payCycleMap.find((el) => el.value == payCycle);
    const payCycleText = c ? c.label : "";
    if (jobMinSalary > 1000000 || jobMaxSalary > 1000000) {
      str = `${floor(jobMinSalary / 1000000, 2)}-${floor(
        jobMaxSalary / 1000000,
        2
      )} 万/${payCycleText}`;
    } else {
      str = `${floor(jobMinSalary / 100)}-${floor(
        jobMaxSalary / 100
      )} 元/${payCycleText}`;
    }
  } else if (flag == 3) {
    str = salaryDescribe;
  }
  return str;
}
//从链接下载
export function downFromUrl(url, fileName) {
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = url;
  tempLink.setAttribute("download", fileName);
  tempLink.setAttribute("target", "_blank");
  // 挂载a标签
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  // 释放blob URL地址
  // window.URL.revokeObjectURL(blobURL);
}

// 二进制流转文件并下载
export default function convertRes2Blob(response) {
  let disposition =
    response.headers["content-disposition"] ||
    response.headers["Content-Disposition"];
  // 提取文件名
  let fileName;
  if (disposition) {
    fileName =
      (disposition.match(/filename="(.*)";/) &&
        disposition.match(/filename="(.*)";/)[1]) ||
      disposition.match(/filename=(.*)/)[1];
  }
  // 将二进制流转为blob
  fileName = decodeURI(fileName);
  console.log(fileName);
  const blob = new Blob([response.data], { type: response.data.type });
  // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    window.navigator.msSaveBlob(blob, decodeURI(fileName));
  } else {
    // 创建新的URL并指向File对象或者Blob对象的地址
    const blobURL = window.URL.createObjectURL(blob);
    // 创建a标签，用于跳转至下载链接
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", decodeURI(fileName));
    // 兼容：某些浏览器不支持HTML5的download属性
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }
    // 挂载a标签
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    // 释放blob URL地址
    window.URL.revokeObjectURL(blobURL);
  }
}

//是否是微信浏览器
export function isWeiXin() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}

//是否是郑好办浏览器
export function isZhengHaoBan() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.match(/zhenghaoban/i) == "zhenghaoban") {
    return true;
  } else {
    return false;
  }
}

//随机id
export function guid(len = 32, firstU = true, radix = null) {
  let chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  let uuid = [];
  radix = radix || chars.length;

  if (len) {
    // 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;
    // rfc4122标准要求返回的uuid中,某些位为固定的字符
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";

    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  // 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
  if (firstU) {
    uuid.shift();
    return "u" + uuid.join("");
  } else {
    return uuid.join("");
  }
}
