import * as jobApi from "@/api/job";
import { checkErrCode } from "@/utils";
const store = {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    getIndexJobList({ commit, dispatch }, payload) {
      const params = {
        apiName: "/recruit/job-info/getJobInfoList",
        paramMap: payload,
        requestType: 1,
      };
      return new Promise((resolve, reject) => {
        jobApi
          .getIndexJobList(params)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default store;
