import * as api from "@/api/houseSurvey";
import { checkErrCode } from "@/utils";
const store = {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    postAddRent({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .postAddRent(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postRentList({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .postRentList(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postAddTalentHouse({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .postAddTalentHouse(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postTalentHouseList({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .postTalentHouseList(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getTalentActivities({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .getTalentActivities(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addTalentActivities({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .addTalentActivities(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getSmsValid({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .getSmsValid(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default store;
