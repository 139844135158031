import Vue from "vue";
import Vuex from "vuex";
import global from "./global";
import policy from "./policy";
import job from "./job";
import jobfairs from "./jobfairs";
import houseSurvey from "./houseSurvey";
import wxNotice from "./wxNotice";
//自动引入modules
// const modulesFiles = require.context("./", true, /\.js$/);

// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//   // set './app.js' => 'app'
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
//   const value = modulesFiles(modulePath);
//   modules[moduleName] = value.default;
//   return modules;
// }, {});

Vue.use(Vuex);

const store = new Vuex.Store({
  // modules,
  modules: {
    global,
    policy,
    job,
    jobfairs,
    houseSurvey,
    wxNotice,
  },
});

export default store;
