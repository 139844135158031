import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.less";
import "@/styles/index.scss";
import defaultImg from "@/assets/default.png";
import VConsole from "@/utils/vconsole";
import Calendar from "vue-mobile-calendar";

import filters from "./common/filters";

//注册全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
if (process.env.NODE_ENV === "development") {
  new VConsole();
}
Vue.use(Calendar);
Vue.use(Vant);
Vue.config.productionTip = false;
Vue.prototype.$defaultImg = defaultImg; //默认图片

function setHtmlFontSize() {
  const htmlWidth =
    document.body.clientWidth || document.documentElement.clientWidth;
  const htmlDom = document.getElementsByTagName("html")[0];
  htmlDom.style.fontSize = `${htmlWidth / 7.5}px`;
}
setHtmlFontSize();
window.addEventListener("resize", setHtmlFontSize);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
