import * as policyApplyApi from "@/api/policy/apply";
import { Toast } from "vant";
import { checkErrCode } from "@/utils";
const store = {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    /* 高层次人才认证查询 */
    gccrcsbhy({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        policyApplyApi
          .gccrcsbhy(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /* 人才认证查询 */
    getUserTypeByIdcard({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        policyApplyApi
          .getUserTypeByIdcard(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res.data);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /* 人才认证查询 多类型查询 */
    getUserTalentTypesByIdcard({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        policyApplyApi
          .getUserTalentTypesByIdcard(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res.data);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }
};

export default store;
