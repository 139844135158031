import request from "@/utils/request";

/* 查询申报详情 */
export function getDetailInfo(params) {
  return request({
    url: "/policy/Apply/getDetailInfo",
    method: "GET",
    params
  });
}

/* 查询申报表单 */
export function getApplyInfo(params) {
  return request({
    url: "/policy/item/getItemInfo",
    method: "GET",
    params
  });
}
/* 查询表单详情 */
export function getFormDetail(params) {
  return request({
    url: "/policy/item/getFormDetail",
    method: "get",
    params
  });
}

/* 查询表单详情 */
export function uploadFile(data) {
  return request({
    // url: "/policy-common/fileManage/uploadFile",
    url: "/policy/file-manage/uploadFile",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data
  });
}

/* 提交办事项申请 */
export function postApply(data) {
  return request({
    url: "/policy/item-apply-info/add",
    method: "POST",
    data
  });
}

// 获取审批任务列表
export function applyInfoPage(data) {
  return request({
    // url: "/policy/item-apply-info/page",
    url: "/policy/item-apply-info/queryPage",
    data,
    method: "POST"
  });
}

//获取审批任务详情
export function applyInfoDetail(params) {
  return request({
    url: "/policy/item-apply-info/info",
    params,
    method: "GET"
  });
}

// 获取审批任务列表
export function itemProcessProgress(params) {
  return request({
    url: "/policy/item-process/progress",
    params,
    method: "GET"
  });
}
/* 高层次人才认证 */
export function gccrcsbhy(params) {
  return request({
    url: "/policy/sdsjj/gccrcsbhy",
    method: "GET",
    params
  });
}
/* 人才认证查询 */
export function getUserTypeByIdcard(params) {
  return request({
    url: "/policy/library/getUserByIdcard",
    method: "GET",
    params
  });
}

/* 人才认证查询 多类型查询 */
export function getUserTalentTypesByIdcard(params) {
  return request({
    url: "/policy/new/library/getTalentLibrary",
    method: "GET",
    params
  });
}

/* 查询申报详情 */
export function getApplyDetailInfo(params) {
  return request({
    url: "/policy/item-apply-info/getDetailInfo",
    method: "GET",
    params
  });
}
