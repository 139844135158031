
import { isZhengHaoBan, isWeiXin } from "@/utils";
import { zhbLogin, wxLogin, postRealNameAuthen } from "@/api/pclogin/uia";
import { removeToken, setToken, getToken } from '@/utils/auth';
import { getUserInfo } from '@/api/user';
import { Toast } from 'vant';
import router from '@/router'
import { queryRealName } from "@/api/userinfo/uia"
import { H5RealNameAuthen } from "@/api/userinfo/uia"
import Cookies from "js-cookie";

function wxAuth() {
  const appid = process.env.VUE_APP_WXAPPID;
  const redirect_uri = encodeURIComponent(window.location.href);
  const state = '';
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
  window.location = url;
}
function zhengHaoBanReady(callback) {
  if (window.AlipayJSBridge) {
    callback && callback();
  } else {
    document.addEventListener("AlipayJSBridgeReady", callback, false);
  }
};
if (isZhengHaoBan()) {
  zhengHaoBanReady();
}

const store = {
  namespaced: true,
  state: {
    isZhengHaoBan: isZhengHaoBan(),  //是否是郑好办环境
    isWeiXin: isWeiXin(),//是否是微信环境
    openid: '', //wenxin openid
    isLogin: false, //是否登录
    isAuth: false, //是否实名
    token: '',//token
    userInfo: null,  //用户信息
    showNav: false,//是否显示顶部导航
    navHeight: 46 //顶部导航高度 px
  },
  mutations: {
    setToken(state, payload) {
      setToken(payload);
      state.token = payload;
    },
    setOpenid(state, payload) {
      Cookies.set("openid", payload, {
        expires: 7,
      })
      state.openid = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setIsLogin(state, payload) {
      state.isLogin = payload;
    },
    setIsAuth(state, payload) {
      state.isAuth = payload;
    },
    setShowNav(state, payload) {
      state.showNav = payload;
    },
  },
  getters: {},
  actions: {
    //授权登录
    authLogin({ commit, state, dispatch }, payload) {
      if (!state.isZhengHaoBan) {
        Toast({
          message: '请使用郑好办登录',
        });
        return;
      }
      if (state.isZhengHaoBan) {
        dispatch('loginByZhengHaoBan')
        return;
      }
      // if (state.isWeiXin) {
      //   dispatch('authWeixin')
      // }
    },
    //微信授权登录 
    authWeixin({ commit, dispatch, state }, payload) {
      wxAuth()
    },
    //微信授权登录 
    loginByWeixinCode({ commit, dispatch, state }, payload) {
      const { code } = payload
      return new Promise((resolve, reject) => {
        wxLogin(payload).then((res) => {
          //存token 登录
          if (res.code != 200) return;
          //存token 登录
          commit('setOpenid', res.data.wechatH5Openid);
          dispatch('loginByToken', res.data.token);
          resolve();
        }).catch(err => {
          reject(err)
        })
      })
    },
    //郑好办授权登录 未授权时先授权，已授权直接返回authcode
    loginByZhengHaoBan({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        console.log(process.env.VUE_APP_ZHB_MODULEID)
        window.AlipayJSBridge.call(
          "userAuth",
          {
            moduleId: process.env.VUE_APP_ZHB_MODULEID,
            scope: "userDetail",
          },
          (res) => {
            console.log(res)
            if (res.data && res.data.authCode) {
              zhbLogin({
                authCode: res.data.authCode,
              }).then((r) => {
                //存token 登录
                dispatch('loginByToken', r.data.token);
                resolve();
              }).catch(err => {
                reject(err)
              })
            } else {
              Toast('授权失败，请稍后再试');
            }
          }
        );
      })
    },
    //微信授权登录
    loginByWeiXin() {

    },
    //token 前端登录
    loginByToken({ commit, state, dispatch }, payload) {
      commit('setToken', payload);
      commit('setIsLogin', true);
      //获取用户信息
      dispatch('getUserInfo');
    },
    // 系统用户退出
    logout({ commit }, payload) {
      commit('setToken', '');
      removeToken();
      commit('setIsLogin', false);
      commit('setIsAuth', false);
      commit('setUserInfo', null)
    },
    //获取用户信息
    getUserInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Toast.loading({
          duration: 0,
          message: '加载中...',
          forbidClick: true,
        });
        queryRealName().then(res => {
          Toast.clear();
          resolve(res)
          if (res.data) {
            commit('setUserInfo', res.data);
            commit('setIsAuth', true);
          }
        }).catch(e => {
          Toast.clear();
          reject(e)
        })
      })
    },
    //实名认证
    postRealNameAuthen({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        H5RealNameAuthen(payload).then(res => {
          Toast('实名认证审核中')
          dispatch('getUserInfo');
          resolve()
        })
      })
    }
  }
};

export default store;
