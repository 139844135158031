import request from "@/utils/request";

/* 查询申请材料 */
export function queryRealName(data) {
  return request({
    url: "/user/user-info/queryUserInfo",
    method: "post",
    data
  });
}

/* 人才公众号h5实名认证：提交实名认证 */
export function H5RealNameAuthen(data) {
  return request({
    url: "/userinfo/uia/H5RealNameAuthen",
    method: "post",
    data
  });
}

/* 人才公众号h5实名认证：提交实名认证 */
export function senSmsCode(params) {
  return request({
    url: "/userinfo/uia/senSmsCode",
    method: "get",
    params
  });
}




