import request from "@/utils/request";

export function getIndexJobList(data) {
  return request({
    url: "/talent-common/external/externalRequest",
    method: "post",
    data,
  });
}
// 职位列表

export function queryJobCateSelf(params) {
  return request({
    url: "/talent-common/external/externalRequest",
    method: "post",
    data,
  });
}
