import axios from "axios";
import Cookies from "js-cookie";
import { APP_AUTH_KEY, APP_USER_SOURCE } from "@/config/constant";
import { Toast } from "vant";
// import router from "../router";
import store from "@/store";
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 20000, // 请求超时时间
});

// 新建请求拦截器
service.interceptors.request.use(
  // 正常请求拦截
  (requestConfig) => {
    if (
      /^\/talent-common\//.test(requestConfig.url)
    ) {
      requestConfig.baseURL = process.env.VUE_APP_BASE_AlI_URL;
    }

    if (Cookies.get(APP_AUTH_KEY)) {
      requestConfig.headers["Authorization"] = Cookies.get(APP_AUTH_KEY); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    requestConfig.headers["User-Source"] = APP_USER_SOURCE;

    if (/^\/pclogin\/sms\//.test(requestConfig.url)) {
      requestConfig.headers["User-Source"] = "PC_ZF";
    }
    return requestConfig;
  },
  // 错误请求拦截
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

// 新建响应拦截器
service.interceptors.response.use(
  // 正常响应拦截
  (response) => {
    const res = response.data;
    if (!res) {
      Toast("响应失败");
      return Promise.reject(res);
    }
    if (!res.code) {
      Toast("响应不正确");
      return Promise.reject(res);
    }
    if (401==res.code) {
      Toast(res.msg);
      store.dispatch("global/logout");
      return Promise.reject(res);
    }
    if (res.code != 200) {
      Toast(res.msg);
      return Promise.reject(res);
    }
    return response.data;
  },
  // 错误响应拦截
  (error) => {
    if ("ECONNABORTED" == error.code && error.message.indexOf("timeout") > -1) {
      Toast({
        message: '当前访问人数过多，请稍后重试',
      });
    }
    switch (error.response.status) {
      case 401:
        store.dispatch("global/logout");
        break;
      case 404:
        console.error("页面404");
        Toast({
          message: '暂无响应，请稍后重试',
        });
        break;
    }
    return Promise.reject(error);
  }
);

export default service;
