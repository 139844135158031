<template>
  <div class="main-layout" :class="showNav ? 'has-nav' : ''">
    <div class="layout-header-wrapper" v-if="showNav">
      <LayoutHeader />
    </div>
    <div class="main-content">
      <router-view></router-view>
    </div>
    <!-- <div class="main-tabbar">
      <van-tabbar route>
        <van-tabbar-item to="/home" icon="home-o"> 首页 </van-tabbar-item>
        <van-tabbar-item to="/job" icon="manager-o"> 人才招聘 </van-tabbar-item>
      </van-tabbar>
    </div> -->
  </div>
</template>
<script>
import LayoutHeader from "./LayoutHeader";
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      showLogin: true,
      activeTab: 1,
    };
  },
  components: { LayoutHeader },
  computed: {
    isZhengHaoBan() {
      return this.$store.state.global.isZhengHaoBan;
    },
    showNav() {
      return this.$store.state.global.showNav;
    },
  },
  created() {
    //自动登录
    if (getToken()) {
      //token登录
      this.loginByToken();
    } else {
      let pathname = window.location.pathname;
      //talent-activities 页面需要个性化处理
      if ("/talent-activities" == pathname) {
        return;
      }
      //微信code登录
      const { code } = this.$route.query;
      if (code) {
        this.$store.dispatch("global/loginByWeixinCode", { code });
      }
    }
  },
  mounted() {},
  methods: {
    loginByToken() {
      this.$store.dispatch("global/loginByToken", getToken());
    },
  },
};
</script>
<style lang="scss" scoped>
$navHeight: 46px;
$tabBarHeight: 50px;
.main-layout {
  position: relative;
  box-sizing: border-box;
  .layout-header-wrapper {
    height: $navHeight;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9999;
  }
  .main-content {
    min-height: 100vh;
    background: #f9f9f9;
    position: relative;
    // padding-bottom: $tabBarHeight;
  }
  &.has-nav {
    padding-top: $navHeight;
    .main-content {
      min-height: calc(100vh - 46px);
    }
  }
  // .main-tabbar {
  //   position: fixed;
  //   height: $tabBarHeight;
  //   background: #ddd;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   z-index: 99;
  // }
}
</style>
