import * as api from "@/api/wxNotice";
import { checkErrCode } from "@/utils";
const store = {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    postWechatH5Openid({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .postWechatH5Openid(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postSyncUserOpenid({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .postSyncUserOpenid(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postQueryUserByOpenid({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .postQueryUserByOpenid(payload)
          .then((res) => {
            if (checkErrCode(res)) {
              resolve(res);
            } else {
              reject(err);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default store;
