import Cookies from "js-cookie";
import { APP_AUTH_KEY } from "@/config/constant";

export function getToken() {
  return Cookies.get(APP_AUTH_KEY) || '';
}

export function setToken(payload) {
  return Cookies.set(APP_AUTH_KEY, payload, {
    expires: 7,
  });
}

export function removeToken() {
  return Cookies.remove(APP_AUTH_KEY);
}