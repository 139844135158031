import request from "@/utils/request";

//新增可出租房屋数量信息
export function postAddRent(data) {
  return request({
    url: "/rent/statistics/add",
    method: "POST",
    data,
  });
}

//获取可出租房屋数量信息
export function postRentList(params) {
  return request({
    url: "/rent/statistics/info",
    method: "get",
    params,
  });
}

//新增人才公寓
export function postAddTalentHouse(data) {
  return request({
    url: "/rent/demand/addRentHouseDemand",
    method: "POST",
    data,
  });
}

//获取人才公寓
export function postTalentHouseList(params) {
  return request({
    url: "/rent/demand/findDemandInfoByPhone",
    method: "get",
    params,
  });
}

//添加人才活动周报名
export function addTalentActivities(data) {
  return request({
    url: "/policy/talent/addApply",
    method: "POST",
    data,
  });
}

//获取人才活动周报名
export function getTalentActivities(params) {
  return request({
    url: "/policy/talent/findApplyInfoByOpenId",
    method: "get",
    params,
  });
}

//验证码
export function getSmsValid(params) {
  return request({
    url: "/rent/statistics/smsValid",
    method: "GET",
    params,
  });
}
