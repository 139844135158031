import request from "@/utils/request";

//获取招聘会信息
export function getJobfairsInfo(data) {
  return request({
    url: "/talent-common/external/externalRequest",
    method: "POST",
    data,
  });
}

//获取招聘会列表

export function postJobfairsJobList(params) {
  return request({
    url: "/talent-common/external/externalRequest",
    method: "POST",
    data,
  });
}

//招聘会列表
export function getfairsList(data) {
  let crtParams = Object.assign({}, { all: 1 }, data);
  return request({
    url: "/talent-common/external/externalRequest",
    method: "post",
    data: crtParams,
  });
}
