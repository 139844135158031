<template>
  <van-nav-bar :title="title">
    <template #left v-if="showLeftArrow">
      <van-icon
        name="arrow-left"
        size="18"
        color="#3f3ad2"
        @click="onClickLeft"
      />
    </template>
  </van-nav-bar>
</template>
<script>
export default {
  data() {
    return {
      title: "人才政策",
      showLeftArrow: true,
    };
  },
  mounted() {},
  methods: {
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>
